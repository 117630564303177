<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            required
            default="today"
            type="year"
            label="평가년도"
            name="vendorGetYear"
            v-model="searchParam.vendorGetYear"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-vendor
            name="vendorCd"
            label="도급업체"
            v-model="searchParam.vendorCd"
          ></c-vendor>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            codeGroupCd="VENDOR_EVALUATION_KIND_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="vendorEvaluationKindCd"
            label="평가구분"
            v-model="searchParam.vendorEvaluationKindCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            codeGroupCd="VENDOR_EVALUATION_KIND_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="vendorEvaluationKindCd"
            label="평가구분"
            v-model="searchParam.vendorEvaluationKindCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            codeGroupCd="EVALUATION_GRADE_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="evaluationGradeCd"
            label="평가등급"
            v-model="searchParam.evaluationGradeCd"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="도급업체 평가대상 목록"
      tableId="table"
      :columns="grid.columns"
      :merge="grid.merge"
      :data="grid.data"
    >
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name === 'vendorName'">
          <q-btn
            class="tableinnerBtn"
            :class="{'tableinnerBtn-left': col.align === 'left' ? true : false,  'tableinnerBtn-right': col.align === 'right' ? true : false, 'tableinnerBtn-ellipsis' : col.ellipsis == true ? true : false}"
            flat
            align="left"
            color="blue-6"
            :label="props.row[col.name]"
            @click.stop="linkClick(props.row, col)" >
              &nbsp;<q-badge v-if="!props.row.mdmVendorEvaluationId" color="negative" label="평가필요" class="blinking" />
            </q-btn>
        </template>
      </template>
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <div>
          <q-btn-group outline>
            <c-btn label="검색" icon="search" @btnClicked="getList" />
          </q-btn-group>
        </div>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'vendor-assess-target',
  data() {
    return {
      searchParam: {
        plantCd: null,
        vendorCd: null,
        vendorEvaluationKindCd: null,
        evaluationGradeCd: null,
        sopConstructionId: '',
        vendorGetYear: '',
      },
      grid: {
        merge: [
          { index: 0, colName: 'vendorCd' },
          { index: 1, colName: 'vendorCd' },
          { index: 2, colName: 'vendorCd' },
        ],
        columns: [
          {
            name: 'plantNames',
            field: 'plantNames',
            label: '사업장',
            style: 'width:100px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'vendorName',
            field: 'vendorName',
            label: '도급업체',
            style: 'width:200px',
            align: 'left',
            type: 'custom',
            sortable: true,
          },
          {
            name: 'workPermitDays',
            field: 'workPermitDays',
            label: '작업허가서 일 수',
            style: 'width:100px',
            align: 'right',
            sortable: true,
          },
          {
            name: 'vendorEvaluationKindName',
            field: 'vendorEvaluationKindName',
            label: '평가구분',
            style: 'width:80px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'approvalStatusName',
            field: 'approvalStatusName',
            label: '상태',
            style: 'width:100px',
            align: 'center',
            sortable: true,
          },
          {
            label: '평가점수',
            child: [
              {
                name: 'totalScore',
                field: 'totalScore',
                style: 'width:100px',
                label: '총점',
                align: 'center',
                sortable: true,
              },
              {
                name: 'score',
                field: 'score',
                style: 'width:100px',
                label: '평점',
                align: 'center',
                sortable: true,
              },
              {
                name: 'evaluationGradeName',
                field: 'evaluationGradeName',
                style: 'width:100px',
                label: '등급',
                align: 'center',
                sortable: true,
              },
            ]
          },
          {
            name: 'evaluationDate',
            field: 'evaluationDate',
            label: '평가기간',
            style: 'width:200px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'remarks',
            field: 'remarks',
            label: '평가결과 종합',
            style: 'width:300px',
            align: 'left',
            sortable: false,
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        isFull: true,
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.mdm.cim.vendor.evaluation.result.targetList.url;
      this.getList();
    },
    getList() {
      if (!this.searchParam.vendorGetYear) {
        window.getApp.$emit("ALERT", {
          title: "안내", // 안내
          message: "평가년도를 선택하세요.",
          type: "warning", // success / info / warning / error
        });
        return;
      }
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.popupOptions.title = "도급업체 평가 상세"; 
      this.popupOptions.param = {
        isNotEval: row.mdmVendorEvaluationId ? false : true,
        mdmVendorEvaluationId: row ? row.mdmVendorEvaluationId : '',
        vendorCd: row ? row.vendorCd : '',
      };
      this.popupOptions.target = () => import(`${"./vendorAssessDetail.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  },
};
</script>
